<template>
  <div>
    <el-dropdown trigger="click" class="w-100">
      <el-button type="info" plain class="el-dropdown-link">
        <div class="valign-center a-center">
          <span>Aktion</span>
          <i class="material-icons m-l-half--md">arrow_drop_down</i>
        </div>
      </el-button>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <el-button @click="onClickShowInvoicePositions" type="text" class="w-100">
            <span>Show invoice positions</span>
          </el-button>
        </el-dropdown-item>

        <el-dropdown-item>
          <a :href="downloadUrl" target="_blank" class="a-center el-button el-button--text w-100">Download CSV</a>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- DIALOG für Rechnungspositionen -->
    <el-dialog title="Rechnungspositionen" :visible.sync="dialogVisible">
      <el-input type="textarea" :rows="24" v-model="invoicePositions" class="w-100"></el-input>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DropdownTrackingResultActions',

  props: {
    results: {
      type: Array,
      required: true
    }
  },

  computed: {
    token() {
      return localStorage.getItem('auth_token');
    },

    downloadUrl() {
      // ToDo: Filter für Company  hinzufügen
      let filter = `filter[start][_gte]=${this.filter.start}`;
      filter += `&filter[end][_lte]=${this.filter.end}`;
      filter += `&filter[billed][_eq]=${this.filter.billed}`;

      if (this.filter.project) {
        filter += `&filter[project][_eq]=${this.filter.project}`;
      }

      if (this.filter.tag) {
        filter += `&filter[tag][_eq]=${this.filter.tag}`;
      }

      if (this.filter.company) {
        // filter += `&filter[project.company][_eq]=${this.filter.company}`;
      }

      let fields = `*.*.*`;
      let limit = `-1`;

      const baseUrl = `${process.env.VUE_APP_API}`;
      let link = `${baseUrl}/timetracker?${filter}&limit=${limit}&fields[]=${fields}&download=csv&token=${this.token}`;

      return link;
    },

    filter() {
      return this.$store.state.tracking.filter;
    },

    user() {
      return this.$store.state.user.current;
    }
  },

  data() {
    return {
      dialogVisible: false,
      invoicePositions: ''
    };
  },

  methods: {
    onClickShowInvoicePositions() {
      this.invoicePositions = '';

      this.results.forEach(result => {
        this.invoicePositions += `- ${result.description} \n`;
      });

      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped lang="scss">
.el-dropdown {
  cursor: pointer;

  &-link {
    width: 100%;
  }
}
</style>
